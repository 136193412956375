/* Settings – used with preprocessors and contain font, colors definitions, etc. */
@import "settings/index";
@import "settings/public-settings/index";
@import "settings/fcsm-settings/index";
@import "../custom_modules/veolia-icons/public/variables";

/* Tools – globally used mixins and functions, not outputs any CSS */
@import "tools/index";

/* Generic - defines properties which can be used in CSS rules (font-family, animations) */
@import "generic/index";

/* Base – styling for bare HTML elements, reset and/or normalize styles */
@import "base/index";

/* Objects – class-based selectors which define undecorated design patterns */
@import "objects/index";
@import "objects/public-objects/index";

/* Components – specific UI components */
@import "components/index";
@import "components/public-components/index";

/* Trumps – utilities and helper classes with ability to override anything */
@import "trumps/index";