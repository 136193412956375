@import "../colors";

$color-primary: $color-prussian-blue;
$color-secondary: $color-fun-green;
$color-tertiary: $color-scarpa-flow;

$gradient-primary: $color-tory-blue, $color-primary;
$gradient-secondary: $color-green-haze, $color-fun-green;
$gradient-tertiary: $color-oslo-gray, $color-scarpa-flow;
$gradient-file-upload: $color-white, $color-alto;

$gradient-primary-inverted: $color-primary, $color-tory-blue;
$gradient-secondary-inverted: $color-fun-green, $color-green-haze;
$gradient-tertiary-inverted: $color-scarpa-flow, $color-oslo-gray;
$gradient-file-upload-inverted: $color-alto, $color-white;

$gradient-apply: $gradient-primary;
$gradient-login: $gradient-primary;
$gradient-share: $gradient-secondary;
$gradient-registration: $gradient-primary;

$color-button-file-upload-border: $color-oslo-gray;
$color-button-file-upload-text: $color-mine-shaft;

$color-header-main-text: $color-primary;
$color-header-inverse-text: $color-white;

$color-favourites-star: $color-fun-green;

$color-searchbar-background-gradient-from: $color-deep-cerulean; // NOTE (bknab): Currently only used in SZV
$color-searchbar-background-gradient-to: $color-primary; // NOTE (bknab): Currently only used in SZV

$color-form-control-elegant-background: $color-white;
$color-form-control-elegant: $color-scarpa-flow;
$color-form-control-elegant-placeholder: $color-oslo-gray;
$color-form-control-elegant-hover: $color-mine-shaft;
$color-form-control-elegant-focus: $color-scarpa-flow;

$color-position-card-footer: $color-alto;
$color-position-card-header-text: $color-white;
$color-position-card-header-gradient-from: $color-primary;
$color-position-card-header-gradient-to: $color-primary;
$color-position-card-header-priority-gradient-from: $color-green-haze;
$color-position-card-header-priority-gradient-to: $color-fun-green;
$color-position-card-footer-text: $color-scarpa-flow;
$color-position-card-footer-link: $color-primary;
$color-position-card-content-font: $color-white;
$color-position-card-content-transcluent: rgba($color-primary, .8);
$color-position-card-star: $color-fun-green;
$color-position-card-discriminated-border: $color-position-card-header-gradient-to;

$color-hero-text: $color-white;
$color-hero-mantled-text: $color-white;
$color-hero-mantle-transcluent: rgba($color-primary, .8);
$color-hero-mantle-white-transcluent: rgba($color-white, .8);
$color-hero-mantle-grey-transcluent: rgba($color-white, .5);

$color-hero-panel-text: $color-white;
$color-hero-panel-background: $color-primary;
$color-hero-panel-inverted-background: $color-white;
$color-hero-panel-inverted-text: $color-primary;

$color-form-member-state-optional: $color-pistachio;
$color-form-member-state-required: $color-burnt-sienna;
$color-form-saved-state: $color-oslo-gray;

$color-form-design-element-focus: $color-primary;

$color-form-help-warning: $color-burnt-sienna;
$color-form-help-success: $color-pistachio;

$color-form-validation-error: $color-guardsman-red;

$color-header-menu-item: $color-secondary;

$color-dropdown-text-action: $color-white;
$color-dropdown-background-action: $color-secondary;

$color-profile-avatar-border: $color-secondary;
$color-profile-avatar-mantle-white-transcluent: rgba($color-white, .8);
$color-profile-avatar-label-text: $color-scarpa-flow;

$color-application-state-success: $color-pistachio;
$color-application-state-fail: $color-burnt-sienna;

$color-error-page-access-denied-icon: $color-primary;
$color-error-page-heading: $color-primary;